<template>
	<div
		class="ielts-page"
		:class="$route.path.indexOf('prediction') > -1 ? 'bgGray' : ''"
	>
<!--		<div class="ielts-tab-box">-->
<!--			<div class="ielts-tab">-->
<!--				<div class="box"></div>-->
<!--				<div-->
<!--					class="ielts-tab-item"-->
<!--					v-for="item in ieltsTab"-->
<!--					:key="item.url"-->
<!--				>-->
<!--					<router-link :to="item.url">-->
<!--						<div-->
<!--							class=""-->
<!--							:class="[-->
<!--								`icon-papa-${item.icon}`,-->
<!--								item.tag ? 'hasTag' : '',-->
<!--							]"-->
<!--						>-->
<!--							{{ item.name }}-->
<!--						</div>-->
<!--					</router-link>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--		<div class="ielts-content">-->
<!--			<router-view name="ielts"></router-view>-->
<!--		</div>-->
<!--		<FloatBar category="ielts"></FloatBar>-->
<!--    <FloatAds></FloatAds>-->

	</div>
</template>

<script>
import IeltsJs from './Ielts.js';

export default IeltsJs;
</script>

<style lang="less">
.ielts-page {
	overflow: hidden;
	background: #fff;
	& > .ielts-tab-box {
		width: 100%;
		border-bottom: 1px solid #f2f2f2;
		background: #fff;

		& > .ielts-tab {
			height: 40px;
			line-height: 40px;
			width: 1200px;
			margin: 0 auto;
			display: flex;

			& > .box {
				margin-right: 170px;
			}

			& > .ielts-tab-item {
				margin-right: 2em;

				& > a {
					color: #555;
					font-size: 14px;
				}

				& > .router-link-active {
					color: #29d087;
				}
				.hasTag {
					position: relative;
				}
				.hasTag::after {
					content: 'NEW';
					position: absolute;
					color: #ff3e3e;
					top: -7px;
					font-size: 12px;
					right: -32px;
				}
			}
		}
	}
}
.bgGray {
	background: #f0f0f0;
}
</style>
